export const constants = {
  CHANGE_LANG: "CHANGE_LANG",
  SET_TOKEN: "SET_TOKEN",
  SET_WEEKLY_RESOURCES: "SET_WEEKLY_RESOURCES",
  SET_NAME: "SET_NAME",
  SET_FG_MODULES:"SET_FG_MODULES",
  SET_PFG_MODULES:"SET_PFG_MODULES",
  SIGN_IN_MODAL:"SIGN_IN_MODAL",
  SET_GLOBAL_ALERT:"SET_GLOBAL_ALERT",
};
