import React, { useRef, useState } from "react";
import classes from "./index.module.scss";
import { Col} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";



const VideoCard = ({ item, type, inPlace, wrapper }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const videoEl = useRef(null);

	let markers = item?.video_segmentations?.map((segment)=>{
				return {
					title:segment.activity,
					time:Number(lang=="en"?segment.start:segment.start_ar),
					color:'#ffc837'
				};
			})

	const videoOptions = {
		previewThumbnails:{enabled:true, src:lang=="en"?item?.thumbnail:item?.thumbnail_ar},
		markers: { enabled: true, points: markers }
		};
	return (
		<React.Fragment>
			<Col

				className={`mx-auto ${classes.card}`}>
					<div className={classes.relative}>
						<Plyr
						ref={videoEl}
						source={{
						type: "video",
						sources: [
							{
							src: lang == "en" ? item?.media : item?.media_arabic,
							}
						]
						}}
						options={videoOptions}
						/>
					</div>

					<div className={`${classes.resource_content}`}>
						{wrapper&&<span className="f-14 semi_bold">{wrapper}</span>}
						<p className="f-16 semi_bold text-dark">{lang == "en" ? item?.title : item?.title_arabic}</p>
					</div>
			</Col>


		</React.Fragment>
	);
};

export default VideoCard;
