import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Ratio } from "react-bootstrap";
import images from "../../resources/images";
import classes from "./index.module.scss";

const VideoModal = ({ handleClose, show, src }) => {
	const [isPlayed, setIsPlayed] = useState(false);
	const [showControlFlag, setShowControlFlag] = useState(false)

	const videoEl = useRef(null);

    const playVideo = () => {
        videoEl.current?.play()
        setIsPlayed(true)
        setShowControlFlag(true)
    }
    const pauseVideo = () => {
        videoEl.current?.pause()
        setIsPlayed(false)
        setShowControlFlag(false)
    }

	return (
		<Modal show={show} onHide={handleClose}  keyboard={false} className={classes.modalWrapper}>
			<Modal.Header closeButton className={`p-0`}></Modal.Header>
			<Modal.Body className={`p-0`}>
				<a onClick={!isPlayed ? playVideo : pauseVideo}>
					{!isPlayed && <div className={`overlay ${classes.custom}`}></div>}

					<Ratio aspectRatio="16x9">
						<video loop ref={videoEl}  controls={showControlFlag}  controlsList="nodownload"
                            onEnded={() => {
                                setIsPlayed(false)
                            }}
                            onPause={() => {
                                setIsPlayed(false)
                                setShowControlFlag(false)
                            }}>
							<source src={src} type="video/mp4" />
						</video>
					</Ratio>
					<div className={`${classes.play} border-0`}>{isPlayed ? <images.pause /> : <images.videoIcon />}</div>
				</a>
			</Modal.Body>
		</Modal>
	);
};

export default VideoModal;
