import React, { Fragment, useState, useEffect } from "react";
import images from "../../resources/images";
import { Row, Col } from "react-bootstrap";
import { API_getFacilitatorWeeks } from "../../store/actions/facilitatorActions";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import WeekHeroSection from "../../components/WeekHeroSection"


import classes from "./index.module.scss";
import FacilitatorAccordion from './components/FacilitatorAccordion';
import { useDispatch, useSelector } from "react-redux";
import FeaturedComponent from "../../components/FeaturedComponent";
import {
	BrowserRouter as Router,
	useParams,
	useLocation

  } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";



const FacilitatorWeek = () => {
	const params=useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const lang = useSelector((state) => state.settingsReducer.lang);
	const token = localStorage.getItem("token")
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState("");
	const location = useLocation();



	useEffect(async () => {
		setLoading(true);
		const res = await dispatch(API_getFacilitatorWeeks(params.module,params.week));
		setData(res.data);
		setLoading(false);

	}, [location, token]);

	return (
		<AuthLayout>
		{loading ? (
			<div className={classes.loading}>
				<ReactLoading type={"spin"} color={"#43919e"} height={667} width={100} />
			</div>
		) : (
			<React.Fragment>
				<WeekHeroSection weeklyResources={data?.weekly_resources} module={data.module} slug={data?.slug} order={data?.order} title={lang=="en"?data?.title:data?.title_arabic}/>

				<section className="m-block">
					<h4 className="mb-5 f-32 bold text-center">{t("Module Content")}</h4>
					<FacilitatorAccordion days={data?.days} weeklyResources={data?.weekly_resources}  week={lang=="en"?data?.title:data?.title_arabic} module={lang=="en"?data?.module.title:data?.module.title_arabic}/>
				</section>
				<div className="p-inline m-bottom ">
						<FeaturedComponent image={images.bluePattren} icon={<images.checklistIcon />} btnText={t("Learn More about Caregiver Guidance")} navigation={true} route={'/caregiver'}>
							<h3 className="bold f-28">{t("How Can you Help Children Learn and Grow?")}</h3>
							<span className="d-inline-block">{t("Enjoy doing fun and easy activities every day with children chicklists")}</span>
						</FeaturedComponent>
					</div>
			</React.Fragment>
		)}
	</AuthLayout>


	);
}
export default FacilitatorWeek;
