export const translation_ar = {
	"Program design core elements": "العناصر الأساسية لتصميم البرنامج",
	"Collaborative relationships between parents/families with their children & classroom educators.":
		"العلاقات التعاونية بين الآباء / العائلات مع أطفالهم ومعلمي الصف.",
	"Developmental benchmarks guide & indicators for what children should know and be able to do, with adult support.":
		"دليل المعايير التنموية والمؤشرات لما يجب أن يعرفه الأطفال وأن يكونوا قادرين على القيام به ، بدعم من الكبار.",
	"The interactions between adults and children and includes 8 key together practices.":
		"التفاعلات بين البالغين والأطفال وتتضمن 8 ممارسات رئيسية معًا.",
	"Language development tools to support children in developing communication skills.": "أدوات تطوير اللغة لدعم الأطفال في تنمية مهارات الاتصال.",
	"Egyptian STEM Early Years Program Design": "برنامج STEM للطفولة المبكرة",
	"Central to the design are the themes, which are based on a variety of STEM-based topics and will be presented in 12 modules throughout the year (one per month).":
		"محور التصميم هو الموضوعات ، التي تستند إلى مجموعة متنوعة من الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات وسيتم تقديمها في 12 وحدة على مدار العام (واحدة في الشهر).",
	"Together Practices": "معا الممارسات",
	"The interactions between adults & children": "التفاعلات بين الكبار والأطفال",
	"Children Guide": "دليل الأطفال للقراءة",
	"Oral Language development tools": "أدوات تطوير اللغة الشفهية",
	"Parent Family Guide": "دليل عائلة الوالدين",
	"Domains and Supportive Practices": "المجالات والممارسات الداعمة",
	"collaborative relation building activities": "دليل بناء العلاقات التعاونية",
	"Developmental benchmarks guide": "دليل معايير التنمية",
	"STEM Program Developmental Levels": "المراحل العمرية لبرنامج STEM",
	"Caregiver Resources": "دليل مقدم الرعاية",
	"Facilitator Resources": "دليل الميسرة",
	"Parent Family Resources": "أنشطة أولياء الأمور",
	"Egyptian STEM for Early Years Program": "برنامج STEM للطفولة المبكرة",
	"Helping children from 0 - 4 years old to explore, learn, and grow in ways that prepare them for future success early.":
		"مساعدة الأطفال من سن 0 إلى 4 سنوات على الاستكشاف والتعلم والنمو بطرق تعدهم للنجاح في المستقبل مبكرًا.",
	"The design of the program is centered on 2 core elements": "يتمحور تصميم البرنامج على عنصرين أساسيين",
	"Thematic Instruction": "تعليم مواضيعي",
	Collaboraion: "تعاون",
	"Thematic instruction which are based on a variety of STEM-based topics.":
		"التعليمات الموضوعية التي تستند إلى مجموعة متنوعة من الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات.",
	"Fostering of collaborative relationships among classroom educators & parents/families.":
		"تعزيز العلاقات التعاونية بين المعلمين في الفصول الدراسية وأولياء الأمور / الأسر.",
	"From the day they are born, children are natural explorers, scientists, and learners. Parents and caregivers confirm that children love to experiment, observe, and explore. STEM, which is an international acronym meaning science, technology, engineering, and math, is a perfect fit for the curiosity and imagination of children.":
		"من يوم ولادتهم ، يعتبر الأطفال مستكشفين طبيعيين وعلماء ومتعلمين. يؤكد الآباء ومقدمو الرعاية أن الأطفال يحبون التجربة والمراقبة والاستكشاف. STEM ، وهو اختصار دولي يعني العلوم والتكنولوجيا والهندسة والرياضيات ، مناسب تمامًا لفضول الأطفال وخيالهم. ",
	"STEM-Based Themes": "الموضوعات القائمة على العلوم والتكنولوجيا والهندسة والرياضيات",
	"Structured Play & Hands-on Learning": "اللعب المنظم والتعلم العملي",
	"STEM Together Practices": "ممارسات STEM معًا",
	"Welcome to STEMEY - PLJ  platform! Embark on the Children's journey as they start exploring the world around them":"مرحبا بكم في منصة برنامج STEM للطفولة المبكرة و يسعدنا انضمامكم إلينا في رحلة الإستكشاف مع الأطفال",
	"Sponsored By": "برعاية",
	"Useful Links": "روابط مفيدة",
	"8 weekly together practices": "8 أنشطة أسبوعية متنوعة",
	"Facilitator Guidance": "دليل الميسرة",
	"Children Guidance": "دليل الأطفال للقراءة",
	"3 Together activities/Practices per day.": "3 أنشطة / ممارسات معًا يوميًا.",
	"Suggested weekly activities": "الأنشطة الأسبوعية المقترحة",
	"Separate instructions for observers and explorers.": "تعليمات منفصلة للمراقبين والمستكشفين.",
	"4 nonfictional Texts (1 per week, to be read aloud during “Read Together” activity)": "4  نصوص (يقرأ نص في الأسبوع مع الأطفال خلال نشاط هيا نقرأ معًا).",
	"1 fictional story (1 per theme, to be read during the Circle time or dismissal)": "1  قصة خيالية (تقرأ القصة على مدار الوحدة خلال وقت لقاء الصباح ووقت القصة ).",
	Week: "أسبوع",
	"Central to the design are the themes, which are based on a variety of STEM-Based topics and will be presented in 12 modules throughout the year (one per month).":
		"محور التصميم هو الموضوعات ، التي تستند إلى مجموعة متنوعة من الموضوعات المستندة إلى العلوم والتكنولوجيا والهندسة والرياضيات وسيتم تقديمها في 12 وحدة على مدار العام (واحدة في الشهر).",
	"The first years in a child’s life are so important. Tracking how little ones play, learn, speak, act and move can help you support their development and act quickly in the event of a delay.":
		"السنوات الأولى في حياة الطفل مهمة للغاية. يمكن أن يساعدك تتبع كيف يلعب الأطفال ويتعلمون ويتحدثون ويتصرفون ويتحركون في دعم تطورهم والتصرف بسرعة في حالة التأخير.",
	"Interested in Saving your Child Checklist? Sign in with Google!": "هل أنت مهتم بحفظ قائمة التحقق الخاصة بطفلك؟ الدخول مع جوجل!",

	"Caregiver Guidance": "دليل مقدم الرعاية",
	Observers: "البراعم",
	Explorers: "الزهور",
	Discover: "اكتشف",
	"Let's Start": "إبدأ الآن",

	"Take a deep dive into resources": "تعمق في الموارد",

	"Track your child’s developmental progress by looking for developmental milestones using an interactive, illustrated checklist.":
		"لاحظ نمو طفلك وما يستطيع فعله باستخدام قائمة تحقق تفاعلية لما يفعله معظم الأطفال في هذا العمر .",

	"Help your baby learn and grow by applying what you can do with them at his developmental milestone. Talk, read, sing, and play together every day.":
		"ساعد طفلك على التعلم والنمو باستخدام الأنشطة المصممة لهذه المرحلة العمرية. تحدثوا واقرأوا وغنوا والعبوا معًا كل يوم.",

	"Ignite your Observers Curiosity!": "إثارة فضول البراعم !",

	"Engage with a variety of STEM Together Practices designed to foster a cultural shift around STEM teaching and learning.":
		"لنتعمق في مجموعة متنوعة من ممارسات STEM معًا المصممة لتعزيز التحول فى ثقافة تعليم وتعلم العلوم والتكنولوجيا والهندسة والرياضيات.",

	"12 Theme per year": "١٢ وحدة في السنة ",
	"1 Module per month": "١ وحدة في الشهر",
	"8 Together Practicies per week": " ٨ ممارسات معا إسبوعيا ",

	"Milestones Matter!": "ملامح هامة !",
	"How your child plays, learns, speaks, acts, and moves offers important clues about his or her development.":
		"إن الطريقة التي يلعب بها طفلك ويتعلم ويتحدث ويتصرف ويتحرك توفر أدلة مهمة حول تطوره",

	Facilitator: "الميسرة","facilitator":"الميسرة",
	"parent":"ولي الأمر",
	"Facilitator Guidance": "دليل الميسرة",
	"Empowering facilitators with": "يوفر الدليل:",
	"Suggested weekly activity grid.": "جدول الأنشطة المشتركة الأسبوعية لكل وحدة",
	"Simple detailed instructions per STEM Together activity.": "خطوات مفصلة وبسيطة لكل نشاط من أنشطة هيا … معًا",

	"'Children Guidance": "دليل الأطفال للقراءة",
	"Providing Children with": "يوفر الدليل :",
	"Excellent opportunity to develop literacy skills through the Oral language development prompts.":
		"فرص لتطوير مهارة القراءة من خلال التفاعل مع أسئلة وأدوات تطوير اللغة الشفوية ",
	"4 non-Fictional stories per Module.": "٤ نصوص لكل وحدة",
	"1 Fictional story per Module.": "قصة لكل وحدة",

	"Parent/Family": "ولى الأمر",
	"Parent/Family Engagement activities": "إرشاد ولى الأمر / الأسرة",
	"Providing Families with": "توفر أنشطة أولياء الأمور",
	"Meaningful interactions between them and their children.": "تفاعلات هادفة بين أولياء الأمور والأطفال متعلقة بموضوع كل وحدة",
	"5 family Together Practices per week (1 Together practice per day).": "5 أنشطة أسبوعية",
	"Unlock Explorers Potentials!": "تعزيز إمكانيات الزهور!",
	"Take Talking, Creating and Solving Together along with other STEM together practices to higher developmental level as your explorers start demonstrating better observation, prediction, and collaboration skills.":
		"انتقل للحديث عن الإبداع وايجاد الحلول بما يتماشى مع ممارسات العلوم والتكنولوجيا والهندسة والرياضيات بهدف الوصول إلى مستوى نموأعلى يبدأ فيه الزهور في إظهار مهارات أفضل في الملاحظة والتنبؤ والتعاون.",

	"Celebrate your Children KG Readiness with Edu. 2.0!": "احتفل باستعداد أطفالك لمرحلة رياض الأطفال فى منظومة تعليم 2.0!",
	"As your Children grow older and ready for formal learning, pave a smooth transition to schools by implementing KG1/KG2 Edu. 2.0 Multidisciplinary program in your nurseries":
		"مع تقدم أطفالك في السن واستعدادهم للتعلم الرسمي ، قم بتمهيد الانتقال لمرحلة رياض الاطفال فى منظومة تعليم 2.0 من خلال تطبيق برنامج متعدد التخصصات (أكتشف) .",

	"Dive deep into Edu. 2.0 KG1/KG2 Resources": "تعمق في Edu. 2.0 KG1 / KG2 الموارد",

	"Teacher's Guide": "دليل المعلم",
	"Student's Guide": "دليل الطالب",

	"STEM-EY Digital Library": "مكتبة STEM-EY الرقمية",
	"STEM programming encourages and supports children in making a meaning out of their curiosity, exploration and experiences":
		"أسئلة وأدوات تطوير اللغة الشفهية",

	"Caregiver Resources Include": "تشمل موارد مقدم الرعاية",
	"What most babies do by this age checklist at different ages from 0-24 months.":
		" ما يفعله معظم الأطفال في مختلف الأعمار( 0-24) شهرًا بإستخدام قائمة المراجعة العمرية.",
	"Suggested activities to enjoy with your with children": "أنشطة مقترحة للاستمتاع بها مع أطفالك",
	"Interested in Saving your Child Checklist? Sign in with Google!": "هل أنت مهتم بحفظ قائمة التحقق الخاصة بطفلك؟ الدخول مع جوجل!",
	"Sign in With Google": "تسجيل الدخول بحساب جوجل",
	"5 simple Together Practice activities per week organized in a calendar, aligned with the weekly classroom theme": "5 أنشطة بسيطة من ممارسات هيا ......  معًا في جدول أسبوعي مُقترح ، تتماشى مع ما يتعلمه و يمارسه الأطفال في الحضانة",
	"1 practice per a day": "نشاط واحد في اليوم",
	"Module Content": "محتويات الأسبوع",

	"Activities to do with your class": "نشاط لتفعله في القاعة",
	"Activities to do with your child": "نشاط لتفعله مع طفلك",

	"More Resources": "المزيد من الموارد",
	"For more resources you can explore full guidance and videos we provided for the module":
		"لمزيد من الموارد ، يمكنك استكشاف الإرشادات الكاملة ومقاطع الفيديو التي قدمناها للوحدة",

	"Your Baby": "طفلك",
	"Milestones matter! How your child plays, learns, speaks, acts, and moves offers important clues about his or her development. Check the milestones your child has reached by 18 months. Take this with you and talk with your child’s doctor at every well-child visit about the milestones your child has reached and what to expect next.":
		"المعالم مهمة! تقدم الطريقة التي يلعب بها طفلك ويتعلم ويتحدث ويتصرف ويتحرك أدلة مهمة حول تطوره. تحقق من المعالم التي وصل إليها طفلك قبل 18 شهرًا. خذ هذا معك وتحدث مع طبيب طفلك في كل زيارة لرعاية الطفل حول المعالم التي وصل إليها طفلك وما يمكن توقعه بعد ذلك.",
	"Help Your Baby Learn and Grow, What You Can Do for Your Child": "ساعد طفلك على التعلم والنمو ، ما يمكنك القيام به لطفلك",

	"Developmental Milestone Checklists": "مراحل تطور نمو الأطفال",
	"Select the appropriate age-range below to view the correct checklist.": "اختر المرحلة العمرية المناسبة التي تتعلق بعمر طفلك",
	"Your Baby": "طفلك",
	"How can you help your child learn and grow?": "كيف يمكنك مساعدة طفلك على التعلم والنمو؟",
	"Enjoy doing fun and easy activities every day with your child": "استمتع بالقيام بأنشطة ممتعة وسهلة كل يوم مع طفلك",

	"What most babies do by this age": "ما يفعله معظم الأطفال في هذا العمر",
	"Child Name": "اسم الطفل",
	"Learn More About Activities": "تعرف على المزيد حول الأنشطة",
	"Your child will be singing this song in class throughout the week.": "سيغني طفلك هذه الأغنية في الحضانة طوال الأسبوع.",
	"Ask them to teach it to you and your family.": "اطلب منهم تعليمها لك ولعائلتك.",
	"Learn More about the Practice": "تعرف على المزيد حول النشاط",
	"Age 0 - 24 Months": "الميلاد - 24 شهور",
	"Age 2 - 3 years": "العمر 2 - 3 سنوات",
	"Age 3 - 4 years": "العمر 3 - 4 سنوات",
	"Age 4 - 5 years": "العمر من 4 - 6 سنوات ",

	Print: "طباعة",
	"Practice Resources": "موارد الممارسة",
	'In "Read Together" Activities, We recommend you to use both the Children guide to read aloud for Children, while follow the activity instructions and tips from the Facilitator guide':"استخدام دليل الأطفال للقراءة مع ارشادات دليل الميسرة",
	"View Pdf": "عرض الملف",
	"©2022 Discovery Education Egypt. All Copyrights reserved":
		"حقوق الطبع والنشر © ديسكفري التعليمية . جميع الحقوق محفوظة لمؤسسة ديسكفري التعليمية.",
	"4 weeks": "٤ أسابيع",
	"4 Multidisciplinary Themes per Year": " ٤ مواضيع متعددة التخصصات في السنة",
	"4 Chapters per Theme": "٤ فصول لكل موضوع",
	"6 to 10 Lessons per Chapter": "من ٦ إلى ١٠ دروس لكل فصل",
	"Opportunities to engage in meaningful interactions between family members and children":"أنشطة تعليمية هادفة يمارسها أفراد الأسرة مع الأطفال",
	"Explore how parents and families can engage with their children through fun and easy Together Practice activities at home. Activities are designed to provide a bridge between content taught in the classroom and experiences at home. Learn about the activities designed to go with the Me and My Community Theme by watching the Parent and Family video.":"اكتشف كيف يتفاعل أولياء الأمور مع أطفالهم في المنزل من خلال أنشطة ممتعة وسهلة تم تصميمها لتتماشى مع ما يتعلمه الأطفال في الحضانة . تعرف على الأنشطة  التي تتناسب مع موضوع الوحدة مجتمعنا و الأشكال و الألوان من خلال مشاهدة فيديو أولياء الأمور .",
	"Suggested Weekly schedule, features 3 STEM blocks with Together Activities":"جدول أسبوعي مقترح يضم 3 أنشطة يومية من أنشطة هيا  ........ معََا",
	"An image Appendix for each module to be used while applying the activities":"ملحق صور لكل وحدة لاستخدامها اثناء تنفيذ الأنشطة",
	"Simple instructions sprouts and flowers":"إرشادات تدريسية بخطوات بسيطة للبراعم والزهور",
	"Supportive practices for non verbal & newly verbal learners":"ممارسات لدعم استجابات الأطفال اللفظية وغير اللفظية",
	"Videos to support facilitators apply specific activities & strategies":"فيديوهات لدعم المسيرات لتنفيذ بعض الأنشطة والاستراتيجيات",
	"Start exploring the theme of each week with your Children by reading out loud  the text of the week in the Children guide":"أبدأ باستكشاف موضوع كل أسبوع مع الأطفال بقرأة النص المخصص له بدليل القراءة للأطفال",
	"Engaging daily opportunities to develop pre literacy skills through oral language development prompts":"فرص يومية ممتعة لإشراك الأطفال في تطوير مهارات ما قبل القراءة",
	"12 Modules":"١٢ وحدة",
	"Channel":"مكتبة",
	"How Can you Help Children Learn and Grow?":"كيف يمكنك مساعدة الأطفال على التعلم والنمو؟",
	"Enjoy doing fun and easy activities every day with children chicklists":"استمتع بالقيام بأنشطة ممتعة وسهلة كل يوم مع قوائم الأطفال",
	"Learn More about Caregiver Guidance":"تعرف على المزيد حول إرشادات مقدم الرعاية",
	"Module":'وحدة',
	"Modules":'وحدات STEM',
	"Fictional Story":"القصص الخيالية",
	"4 Nonfiction stories per Theme":"4 قصص واقعية لكل موضوع",
	"1 Fictional story per Theme":"1 قصة خيالية لكل موضوع",
	"Digital Library":'المكتبة الرقمية',
	"Explore As":"تصفح",
	"Select Theme":"اختر وحدة",
	"Select Week":"اختر أسبوع",
	"Developmental Milestones Checklists and Activities:": "قوائم ملاحظة نمو الأطفال ",
	"Explore": "استكشف",
	"About STEMEY": "عن STEMEY",
	"Parent Program": "برنامج ولي الأمر",
	"Facilitator Program": "برنامج الميسرة",
	"Caregiver Program": "برنامج مقدم الرعاية ",
	"Back": "الرجوع",
	"Program": "برنامج",
	"Explore how parents and families can engage with their children through fun and easy Together practice activities at home": "استكشف وحدات STEM المُصممة لإشراك الأطفال و أولياء الأمور في أنشطة تدعم التطور والنمو من خلال ممارسات هيا .... معًا",
	"Explore how facilitators can engage with their children through fun and easy Together practice activities at class": "استكشف وحدات STEM المُصممة لإشراك الأطفال والميسرات في أنشطة تدعم التطور والنمو من خلال ممارسات هيا .... معًا",

	"Download Guidance": "تحميل الملف الإرشادي",
	"View":"إظهار الكل",
	"Featured Videos":"الفيديوهات المميزة",
	"Video":"فيديو",
	"Sign in":"تسجيل الدخول",
	"Sign in Description":"قم ببناء مهارات اليوم والغد وما بعده.",
	"email":"بريد إلكتروني",
	"password":"كلمة المرور",
	"New Password": "كلمة المرور الجديدة",
	"Reenter password":"أعد إدخال كلمة المرور الجديدة",
	"email placeholder":"أدخل بريدك الإلكتروني",
	"password placeholder": "ادخل كلمة المرور",
	"confirm password":"تأكيد كلمة المرور الجديدة",
	"Forgot Password":"هل نسيت كلمة السر؟",
	"Forgot Password title":"إعادة تعيين كلمة المرور",
	"forgot password description":"لا تقلق، سنساعدك على استعادته",
	"login":"تسجيل الدخول",
	"send":"إرسال",
	"Verify Email":"التحقق من البريد الإلكتروني",
	"Verify email descripiton":"قم بفحص صندوق البريد الخاص بك ستجد otp",
	"reset":"إعادة ضبط",
	"logout":"تسجيل الخروج",
	"email validation":"أدخل عنوان بريد إلكتروني صالح",
	"password validation":"يجب أن تكون كلمة المرور من 5 إلى 12 حرفًا",
	"reEnter password validation":"يجب أن يتطابق الحقلان",
	"logged successfully":"لقد قمت بتسجيل الدخول بنجاح"

};
