import React, { Fragment } from "react";
import { t } from "i18next";
import { Tabs, Tab } from "react-bootstrap";
import classes from "./ProgramLevelSubContant2.module.scss";
const ProgramLevelSubContant2 = (props) => {
	const selectTab = (k) => {
		switch (k) {
			case "facilitator":
				props.setActiveTab(k);
				break;
			case "parent/family":
				props.setActiveTab("parent");
				break;
		}
	};
	return (
		<Fragment>
			<Tabs onSelect={selectTab} defaultActiveKey="facilitator" transition={false} id="noanim-tab-example" className="mb-3">
				<Tab eventKey="facilitator" title={t("Facilitator")}>
					<div className={classes.tab}>
						<div>
							<h3 className={`f-20 meduim`}>{t("Facilitator Guidance")}</h3>
							<p className={`f-16 light`}>{t("Empowering facilitators with")}</p>
							<ul className={classes.pointed_list}>
								<li className={`f-16 light`}>{t("Suggested weekly activity grid.")}</li>
								<li className={`f-16 light`}>{t("Simple detailed instructions per STEM Together activity.")}</li>


							</ul>
						</div>
						<div>
							<h3 className={`f-20 meduim`}>{t("Children Guidance")}</h3>
							<p className={`f-16 light`}>{t("Providing Children with")}</p>

							<ul className={classes.pointed_list}>
								<li className={`f-16 light`}>
									{t("Excellent opportunity to develop literacy skills through the Oral language development prompts.")}
								</li>
								<li className={`f-16 light`}>{t("4 non-Fictional stories per Module.")}</li>
								<li className={`f-16 light`}>{t("1 Fictional story per Module.")}</li>
							</ul>
						</div>
					</div>
				</Tab>
				<Tab eventKey="parent/family" title={t("Parent/Family")}>
					<div className={classes.tab}>
						<div>
							<h3 className={`f-20 meduim`}>{t("Parent/Family Engagement activities")}</h3>
							<p className={`f-18 regular`}>{t("Providing Families with")}</p>
							<ul className={classes.pointed_list}>
								<li className={`f-16 light`}>{t("Meaningful interactions between them and their children.")}</li>
								<li className={`f-16 light`}>{t("5 family Together Practices per week (1 Together practice per day).")}</li>
								<li className={`f-16 light`}>{t("Suggested weekly activity grid.")}</li>
							</ul>
						</div>
					</div>
				</Tab>
			</Tabs>
		</Fragment>
	);
};
export default ProgramLevelSubContant2;
