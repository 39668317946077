import React from "react";
import classes from "./index.module.scss";
import { Accordion } from "react-bootstrap";
import CustomAccordionItem from "../../../../components/CustomAccordionItem";
import images from "../../../../resources/images";
import FeaturedComponent from "../../../../components/FeaturedComponent";
import ResourceCard from "../../../../components/ResourceCard";
import DayCard from "../DayCard";
import { useSelector } from "react-redux";
import { t } from "i18next";

const ParentAccordion = ({ days, weeklyResources, module, week }) => {
	const lang = useSelector((state) => state.settingsReducer.lang);
	const token = localStorage.getItem("token");

	return (
		<React.Fragment>
			<section className="p-inline">

							<h3 className="f-28 semi-bold my-5">{t("Suggested weekly activities")}</h3>
							<div>
								<h4 className="meduim text-center f-20 text-blue">{t("Activities to do with your child")}</h4>

								{days?.map((day, index) => (
									<DayCard
										title="Talk Together"
										icon={<images.parentTalk />}
										color="#3F9CBB"
										isParent={true}
										item={day}
										key={index}
										grid={true}
									/>
								))}

								{/* <DayCard title="Move Together" icon={<images.parentMove/>} color="#9995C7" isParent={true}/>
                         <DayCard title="Pretend Together" icon={<images.parentPretend/>} color="#74BFE9" isParent={true}/> */}
							</div>

							{weeklyResources.map(
								(item, index) =>
									item.featured && (
										<FeaturedComponent
											key={index}
											image={images.purbleBg}
											icon={<images.song />}
											btnText={t("Learn More about the Practice")}
											newTabLink={lang === "ar" ? item.media_arabic : item.media}
											mainData={<img style={{ width: "100%" }} src={lang === "ar" ? item.media_arabic : item.media} />}
											noModalHeader={true}
										>
											<h3 className="bold f-28">{t("Your child will be singing this song in class throughout the week.")}</h3>
											<h3 className="bold f-28">{t("Ask them to teach it to you and your family.")}</h3>
										</FeaturedComponent>
									)
							)}
							{token ? (
								<div className={classes.resources}>
									<div>
										<h3 className="f-28 semi-bold">{t("More Resources")}</h3>
										<p className="f-18 meduim">
											{t("For more resources you can explore full guidance and videos we provided for the module")}
										</p>
									</div>
									<div className={`${classes.resources_wrapper}`}>
										{weeklyResources.map((resource, index) => !resource.featured && resource.type!='Video' && <ResourceCard item={resource} key={index} module={module} week={week}   />)}
									</div>
								</div>
							):null}

			</section>
		</React.Fragment>
	);
};

export default ParentAccordion;
