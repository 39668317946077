import leftArrow from "../../resources/assets/icons/left-arrow.svg"
import styles from "./index.module.scss"
import images from "../../resources/images"
import { useState,useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import backArrow from "../../resources/assets/icons/back-arrow.svg"
import { t } from "i18next";
// import facilitatorGuide from "../../resources"

const MenuBody = ({data,setActiveButton}) => {
  const [showModules,setShowModules] = useState(false)
  const [showWeeks, setShowWeeks] = useState(false)
  const [active, setActive] = useState({
    program: null,
    module: null,
  })
  const [currentModule, setCurrentModule] = useState("")
  const lang = useSelector((state) => state.settingsReducer.lang);
  const [modules, setModules] = useState(null)
  const [weeks, setWeeks] = useState(null)
  const navigate = useNavigate()
  const token = localStorage.getItem("token");
  const programs=[
      {
          title:'facilitator',
          title_ar:'الميسر',
          slug:'facilitator'
      },
      {
          title:'parent',
          title_ar:'ولي أمر',
          slug:'parent'
      }
  ]

  return (
    <>
      {/* <div className={styles.overlay}></div> */}
      <div
        className={styles.menu__container}
        onClick={(e) => e.stopPropagation()}
        onMouseLeave={(e) => { if(window.innerWidth > 950) setActiveButton(false) }}
        >
        <div className={styles.menu__container__submenu}>
          <button
            className={`${styles.menu__link} ${active.program === data.parentModules ? styles.active : ""}`}
            onClick={
              () => {
                setActive(prevState => {
                  return {
                    ...prevState,
                    program: prevState.program === data.parentModules ?
                      null :
                      data.parentModules
                  }
                })
                setModules(data.parentModules)
                if (active.program === data.parentModules) {
                  setShowModules(false);
                  setShowWeeks(false)
                  setActive({
                    program: null,
                    module: null
                  })
                } else {
                  setShowModules(true);
                  setShowWeeks(false)
                }
              }
            }
          >
            {t("Parent Program")}
            <img src={leftArrow} alt=""/>
          </button>
          <button
            className={`${styles.menu__link} ${active.program === data.facilitatorModules ? styles.active : ""}`}
            onClick={
              () => {
                setActive(prevState => {
                  return {
                    ...prevState,
                    program: prevState.program === data.facilitatorModules ?
                      null :
                      data.facilitatorModules
                  }
                })
                setModules(data.facilitatorModules)
                if (active.program === data.facilitatorModules) {
                  setShowModules(false);
                  setShowWeeks(false);
                  setActive({
                    program: null,
                    module: null
                  })
                } else {
                  setShowModules(true);
                  setShowWeeks(false)
                }
              }
            }
          >
            {t("Facilitator Program")}
            <img src={leftArrow} alt=""/>
          </button>
          <button onClick={()=>{
            navigate('/caregiver')
            setActiveButton(false)
          }} className={styles.menu__link}>
            {t("Caregiver Program")}
          </button>
        </div>
        <div className={`${styles.menu__container__modules} ${!showModules ? styles.hide : ""}`}>
          <button
            className={styles.back__btn}
            onClick={() => {
              setActive({
                program: null,
                module: null
              })
              setShowModules(false)
              setShowWeeks(false)
            }}
          >
            <img src={backArrow} alt=""/>
            {t("Back")}
          </button>
          <div className={styles.menu__container__modules__header}>
            <div className={styles.menu__container__modules__header__content}>
              <h3 className={styles.menu__container__modules__header__title}>
                {active.program === data.facilitatorModules ?
                  lang === "ar" ?
                    programs[0].title_arabic :
                    programs[0].title :
                  lang === "ar" ?
                    programs[1].title_arabic :
                    programs[1].title
                  }
              </h3>
              <p className={styles.menu__container__modules__header__text}>
              {active.program === data.facilitatorModules?
                t("Explore how facilitators can engage with their children through fun and easy Together practice activities at class"):t("Explore how parents and families can engage with their children through fun and easy Together practice activities at home")}
              </p>
              {/* {token ? (

              <a className={styles.menu__container__modules__header__link}
                href={
                  active.program === data.facilitatorModules ?
                    lang === "ar" ?
                      "https://dxm24f3wn5min.cloudfront.net/v3.4/PLJ/STEM/EY/FG/FG_AR_full.pdf" :
                      "https://dxm24f3wn5min.cloudfront.net/v3.4/PLJ/STEM/EY/FG/FG_EN_full.pdf"
                    : lang === "ar" ?
                      "https://dxm24f3wn5min.cloudfront.net/v3.4/PLJ/STEM/EY/PFG/PFG_AR_full.pdf" :
                      "https://dxm24f3wn5min.cloudfront.net/v3.4/PLJ/STEM/EY/PFG/PFG_EN_full.pdf"
                }
                onClick={()=>setActiveButton(false)}
                download
                target="_blank"
              >
                {t("Download Guidance")}
              </a>
              ):null} */}
            </div>
            <img
              src={active.program === data.facilitatorModules ? images[`fg_${lang}`] : images[`pfg_${lang}`]}
              className={styles.menu__container__modules__header__img} alt="" />
          </div>
          <div className={styles.menu__container__modules__content}>
            <h3 className={styles.menu__container__modules__content__title}>{t("Modules")}</h3>
            {
              modules?.map(module => {
                return (
                  <p
                    key={module.id}
                    className={`${styles.menu__container__modules__content__link} ${active.module === module.id ? styles.active : ""} ${!module.published ? styles.disabled:""}`}
                    onClick={() => {
                      if(module.published){

                        setActive(prevState => {
                          return {
                            ...prevState,
                            module: prevState.module === module.id ?
                              null :
                              module.id
                          }
                        })
                        setCurrentModule(module)
                        setWeeks(module.weeks)
                        if (window.innerWidth <= 950) {
                          if (active.module === module.id) {
                            setShowWeeks(false)
                            setActive(prevState => ({
                              ...prevState,
                              module: null
                            }))
                          } else {
                            setShowWeeks(true)
                            setShowModules(false)
                          }
                        } else {
                          if (active.module === module.id) {
                            setShowWeeks(false)
                            setActive(prevState => ({
                              ...prevState,
                              module: null
                            }))
                          } else {
                            setShowWeeks(true)
                          }
                        }
                      }
                    }}
                  >
                    { lang === "ar" ? module.title_arabic ? module.title_arabic : module.title : module.title }
                  </p>
                )
              })
            }
          </div>
        </div>
        <div className={`${styles.menu__container__weeks} ${!showWeeks ? styles.hide : ""}`}>
          <button
            className={styles.back__btn}
            onClick={() => {
              setActive({
                program: null,
                module: null
              })
              setShowWeeks(false)
              setShowModules(true)
            }
            }
          >
            <img src={backArrow} alt=""/>
            {t("Back")}
          </button>
          <h3 className={styles.menu__container__weeks__title}>
            { lang === "ar" ? currentModule.title_arabic ? currentModule.title_arabic : currentModule.title : currentModule.title }
            <span
              className={styles.view__all}
              onClick={
                () => {
                  setActiveButton(false)
                  navigate(`/${active.program === data.facilitatorModules ? "facilitator" : "parent"}/${currentModule.slug}`)
                }
              }
            >
              {t("View")}
              <img src={leftArrow} alt="" className={styles.view__all__icon} />
            </span>
          </h3>
          {
            weeks?.map(week => {
              return (
                <div
                  className={styles.menu__container__weeks__card}
                  onClick={
                    () => {
                      setActiveButton(false)
                      navigate(`/${active.program === data.facilitatorModules ? "facilitator" : "parent"}/${currentModule.slug}/week/${week.slug}`)
                    }
                  }
                  key={week.title}
                >
                  <div className={styles.menu__container__weeks__card__content}>
                    <p className={styles.week}>{t("Week")} { week.order }</p>
                    <p className={styles.week__title}>{ lang === "ar" ? week.title_arabic : week.title }</p>
                  </div>
                  <img src={leftArrow} alt="" />
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default MenuBody