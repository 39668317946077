import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import VideoCard from "../VideoCard";
import classes from "./index.module.scss";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Col } from "react-bootstrap";
import whiteArrow from "../../resources/assets/icons/w-left-arrow.svg"




const WeekHeroSection=({weeklyResources, slug, order, title, isParent, media, module})=> {
    const lang = useSelector((state) => state.settingsReducer.lang);
    const facilitator = useSelector((state) => state.facilitatorReducer.facilitatorModules);
    const parent = useSelector((state) => state.facilitatorReducer.parentModules);
    let location=useLocation();
    const [crumbs, setCrumbs] = useState(location.pathname.split('/'))
	const { t } = useTranslation();
    const programs = [
        {
            title: "Facilitator Program",
            title_arabic: 'برنامج الميسرة',
            slug: 'facilitator'
        },
        {
            title: 'Parent Program',
            title_arabic: 'برنامج ولي الأمر',
            slug: 'parent'
        }
    ];
    const [currentProgram, setCurrentProgram] = useState(() => {
        return programs.filter(program => program.slug === crumbs[1])[0]
    })
    const [currentModule, setCurrentModule] = useState(() => {
        if (currentProgram?.slug === "parent") {
            return parent?.filter(module => module.slug === crumbs[2])[0]
        } else {
            return facilitator?.filter(module => module.slug === crumbs[2])[0]
        }
    })
    const [currentWeek, setCurrentWeek] = useState(() => {
        return currentModule?.weeks.filter(week => week.slug === crumbs[4])[0]
    })

    useEffect(() => {
        setCurrentProgram(programs.filter(program => program.slug === crumbs[1])[0])
        if (currentProgram?.slug === "parent") {
            setCurrentModule(parent?.filter(module => module.slug === crumbs[2])[0])
        } else {
            setCurrentModule(facilitator?.filter(module => module.slug === crumbs[2])[0])
        }
        setCurrentWeek(currentModule?.weeks.filter(week => week.slug === crumbs[4])[0])
    },[facilitator,parent,crumbs])

    return(
        <div className={`${classes.hero} p-inline p-block`}>
            <div className={classes.breadcrumb}>
                <p className={`${classes.breadcrumb__text} f-16`}>
                    {
                        lang === "ar" ?
                            currentProgram?.title_arabic :
                            currentProgram?.title
                    }
                </p>
                <img src={whiteArrow} className={classes.breadcrumb__arrow} alt=""/>
                <NavLink className={`${classes.breadcrumb__link} f-16`} to={`/${currentProgram?.slug}/${currentModule?.slug}`}>
                    {t("Module")} {currentModule?.order} : {lang === "ar" ? currentModule?.title_arabic : currentModule?.title}
                </NavLink>
                <img src={whiteArrow} className={classes.breadcrumb__arrow} alt=""/>
                <p className={`${classes.breadcrumb__text} f-16`}>
                    {t("Week")} {currentWeek?.order} : {lang === "ar" ? currentWeek?.title_arabic : currentWeek?.title}
                </p>
            </div>
            <h2 className="f-32 bold mb-4">{t("Week")} {currentWeek?.order}: {title}</h2>
            <Col lg={10} className={`mx-auto m-top px-0`}>
            {isParent?
            <VideoCard item={media}  size={9} inPlace={true} center={true} />
            :
               weeklyResources?.map((item, index) =>
                item.type == "Video" && <VideoCard item={item} key={index} size={9} inPlace={true} center={true} />
            )
            }
            </Col>


        </div>


    )
}

export default WeekHeroSection;